import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
// import SearchIcon from "@mui/icons-material/Search";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  // IconButton,
  Pagination,
  Popover,
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SidebarWrapper from "../../common/components/Sidebar";
import {
  Container,
  Title,
  TableContainer,
  TopContainer,
  RightContainer,
  LoaderContainer,
  ResetFilterButton,
  SearchFilterContainer,
} from "./style";
import { currentNav } from "../../redux/slices/CurrentNavSlice";
import {
  SUCCESS,
  FAILURE,
  ACCESS_TOKEN_EXPIRE,
  SOMETHING_WENT_WRONG,
  VIEW_ASSET,
  ADD_ASSET,
  USER_ACCESS_MANAGEMENT,
} from "../../common/constants";
import { getAssets } from "./services";
import { logoutUser } from "../../common/helpers";
import MultiSelectFilter from "../junctionList/components/MultiStateSearchFilter";
import SearchComponent from "./SearchComponent";
import {
  getAssetTypes,
  getAssetStatus,
  getAssetStates,
  getAssetCities,
  getAssetRegions,
} from "../../common/services/referenceAPI";

const AssetList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params2 = new URLSearchParams(location.search);
  const [searchType, setSearchType] = useState(
    params2.get("searchType") || "asset_id"
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(null);

  // State for filter popover of asset name
  const [selectedAssetName, setSelectedAssetName] = useState(null);
  const [assetNameOptions, setAssetNameOptions] = useState([]);
  const [assetNameAnchorEl, setAssetNameAnchorEl] = useState(null);

  const [loadingFilter, setLoadingFilter] = useState({
    assetType: false,
    state: false,
    city: false,
    area: false,
    status: false,
  });

  // State for filter popover of state
  const [selectedState, setSelectedState] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);
  const [stateAnchorEl, setStateAnchorEl] = useState(null);

  // Add new state for city filter
  const [selectedCity, setSelectedCity] = useState(null);
  const [cityOptions, setCityOptions] = useState([]);
  const [cityAnchorEl, setCityAnchorEl] = useState(null);

  // Add new state for area filter
  const [selectedArea, setSelectedArea] = useState(null);
  const [areaOptions, setAreaOptions] = useState([]);
  const [areaAnchorEl, setAreaAnchorEl] = useState(null);

  // Add new state for status filter
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [statusOptions, setStatusOptions] = useState([]);
  const [statusAnchorEl, setStatusAnchorEl] = useState(null);

  // Fetching asset types
  useEffect(() => {
    const fetchAssetTypes = async () => {
      setLoadingFilter({ ...loadingFilter, assetType: true });
      const response = await getAssetTypes();
      if (response.status === SUCCESS) {
        setAssetNameOptions(response.data);
      } else if (
        response.status === FAILURE &&
        response.message === ACCESS_TOKEN_EXPIRE
      ) {
        logoutUser(dispatch, navigate);
      } else {
        alert(SOMETHING_WENT_WRONG);
      }
      setLoadingFilter({ ...loadingFilter, assetType: false });
    };
    fetchAssetTypes();
  }, []);

  // Fetching asset status
  useEffect(() => {
    const fetchAssetStatus = async () => {
      setLoadingFilter({ ...loadingFilter, status: true });
      const response = await getAssetStatus();
      if (response.status === SUCCESS) {
        setStatusOptions(response.data);
      } else if (
        response.status === FAILURE &&
        response.message === ACCESS_TOKEN_EXPIRE
      ) {
        logoutUser(dispatch, navigate);
      } else {
        alert(SOMETHING_WENT_WRONG);
      }
      setLoadingFilter({ ...loadingFilter, status: false });
    };
    fetchAssetStatus();
  }, []);

  // Fetching the states
  useEffect(() => {
    const fetchStates = async () => {
      const response = await getAssetStates(["IN"]);
      if (response.status === SUCCESS) {
        setStateOptions(response.data);
      } else if (
        response.status === FAILURE &&
        response.message === ACCESS_TOKEN_EXPIRE
      ) {
        logoutUser(dispatch, navigate);
      } else {
        alert(SOMETHING_WENT_WRONG);
      }
    };
    fetchStates();
  }, []);

  // Fetching the cities
  useEffect(() => {
    setCityOptions([]);
    setAreaOptions([]);
    setSelectedCity([]);
    setSelectedArea([]);
    if (selectedState?.length > 0) {
      const fetchCities = async () => {
        const stateIds = selectedState?.map((state) => state.id);
        const response = await getAssetCities(stateIds);
        if (response.status === SUCCESS) {
          setCityOptions(response.data);
        } else if (
          response.status === FAILURE &&
          response.message === ACCESS_TOKEN_EXPIRE
        ) {
          logoutUser(dispatch, navigate);
        } else {
          alert(SOMETHING_WENT_WRONG);
        }
      };
      fetchCities();
    }
  }, [selectedState]);

  // Fetching the regions
  useEffect(() => {
    setAreaOptions([]);
    setSelectedArea([]);
    if (selectedCity?.length > 0) {
      const fetchRegions = async () => {
        const cityIds = selectedCity?.map((city) => city.id);
        const response = await getAssetRegions(cityIds);
        if (response.status === SUCCESS) {
          setAreaOptions(response.data);
        } else if (
          response.status === FAILURE &&
          response.message === ACCESS_TOKEN_EXPIRE
        ) {
          logoutUser(dispatch, navigate);
        } else {
          alert(SOMETHING_WENT_WRONG);
        }
      };
      fetchRegions();
    }
  }, [selectedCity]);

  const handleAssetNameColumnClick = (event) => {
    setAssetNameAnchorEl(event.currentTarget);
  };

  const handleAssetNameClose = () => {
    setAssetNameAnchorEl(null);
  };

  const handleStateClose = () => {
    setStateAnchorEl(null);
  };

  const handleStateColumnClick = (event) => {
    setStateAnchorEl(event.currentTarget);
  };

  const handleCityColumnClick = (event) => {
    setCityAnchorEl(event.currentTarget);
  };

  const handleCityClose = () => {
    setCityAnchorEl(null);
  };

  const handleAreaColumnClick = (event) => {
    setAreaAnchorEl(event.currentTarget);
  };

  const handleAreaClose = () => {
    setAreaAnchorEl(null);
  };

  const handleStatusColumnClick = (event) => {
    setStatusAnchorEl(event.currentTarget);
  };

  const handleStatusClose = () => {
    setStatusAnchorEl(null);
  };

  const handleResetAssetNameFilter = () => {
    console.log("handle Reset Asset Name Filter");
    setSelectedAssetName([]);
    setAssetNameAnchorEl(null);
    // const params = new URLSearchParams();
    // params.set("page", "0");
    // navigate(`?${params.toString()}`);
    // setPage(0);
  };

  const handleResetStateFilter = () => {
    setSelectedState([]);
    setStateAnchorEl(null);
    const params = new URLSearchParams();
    params.set("page", "0");
    navigate(`?${params.toString()}`);
    setPage(0);
  };

  const handleResetCityFilter = () => {
    setSelectedCity([]);
    setCityAnchorEl(null);
    const params = new URLSearchParams();
    params.set("page", "0");
    navigate(`?${params.toString()}`);
    setPage(0);
  };

  const handleResetAreaFilter = () => {
    setSelectedArea([]);
    setAreaAnchorEl(null);
    const params = new URLSearchParams();
    params.set("page", "0");
    navigate(`?${params.toString()}`);
    setPage(0);
  };

  const handleResetStatusFilter = () => {
    setSelectedStatus([]);
    setStatusAnchorEl(null);
    const params = new URLSearchParams();
    params.set("page", "0");
    navigate(`?${params.toString()}`);
    setPage(0);
  };

  const fetchData = async (pageNumber) => {
    setLoading(true);
    const response = await getAssets({
      page: pageNumber,
      searchType,
      searchText: id,
      assetTypeIds:
        selectedAssetName?.length > 0
          ? selectedAssetName?.map((asset) => asset.id)
          : null,
      statusIds:
        selectedStatus?.length > 0
          ? selectedStatus?.map((status) => status.id)
          : null,
      stateIds:
        selectedState?.length > 0
          ? selectedState?.map((state) => state.id)
          : null,
      cityIds:
        selectedCity?.length > 0 ? selectedCity?.map((city) => city.id) : null,
      regionIds:
        selectedArea?.length > 0 ? selectedArea?.map((area) => area.id) : null,
    });
    if (response.status === SUCCESS) {
      setFilteredData(response.data.assetList || []);
      setTotalPages(response.data.pageCount);
    } else if (
      response.status === FAILURE &&
      response.message === ACCESS_TOKEN_EXPIRE
    ) {
      logoutUser(dispatch, navigate);
    } else {
      alert(SOMETHING_WENT_WRONG);
    }
    setLoading(false);
  };

  useEffect(() => {
    dispatch(currentNav(USER_ACCESS_MANAGEMENT));
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const pageFromUrl = parseInt(params.get("page"), 10) || 0;
    setPage(pageFromUrl);
    fetchData(pageFromUrl);
  }, [location.search, id]);

  useEffect(() => {
    if (
      selectedAssetName ||
      selectedState ||
      selectedCity ||
      selectedArea ||
      selectedStatus
    ) {
      setPage(0);
      const params = new URLSearchParams(location.search);
      params.set("page", "0");
      navigate(`?${params.toString()}`);
      fetchData(0);
    }
  }, [
    selectedAssetName,
    selectedState,
    selectedCity,
    selectedArea,
    selectedStatus,
  ]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
    navigate(`?page=${newPage - 1}`);
  };

  const handleRowClick = (assetId) => {
    navigate(`/${VIEW_ASSET}/${assetId}`);
  };

  return (
    <SidebarWrapper>
      <Container>
        <TopContainer>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="text"
              color="primary"
              onClick={() => navigate("/user-access-management")}
              sx={{ mr: 2, minWidth: 0, padding: 0 }}
            >
              <KeyboardArrowLeftIcon />
            </Button>
            <Title>Assets</Title>
          </div>
          <RightContainer>
            <SearchComponent
              searchType={searchType}
              setSearchType={setSearchType}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              setId={setId}
            />
            <Button
              variant="contained"
              color="primary"
              sx={{ ml: 2, backgroundColor: "#E7F5F0", color: "#04A561" }}
              onClick={() => navigate(`/${ADD_ASSET}`)}
            >
              + Add
            </Button>
          </RightContainer>
        </TopContainer>

        <TableContainer>
          <Table sx={{ borderRadius: 0 }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#D5D8DE" }}>
                <TableCell
                  onClick={handleAssetNameColumnClick}
                  sx={{ cursor: "pointer" }}
                >
                  Asset
                  <ArrowDropDownIcon fontSize="small" />
                </TableCell>
                <TableCell>Asset owner</TableCell>
                <TableCell>Phone no.</TableCell>
                <TableCell
                  onClick={handleStateColumnClick}
                  sx={{ cursor: "pointer" }}
                >
                  State
                  <ArrowDropDownIcon fontSize="small" />
                </TableCell>
                <TableCell
                  onClick={handleCityColumnClick}
                  sx={{ cursor: "pointer" }}
                >
                  City
                  <ArrowDropDownIcon fontSize="small" />
                </TableCell>
                <TableCell
                  onClick={handleAreaColumnClick}
                  sx={{ cursor: "pointer" }}
                >
                  Area
                  <ArrowDropDownIcon fontSize="small" />
                </TableCell>
                <TableCell
                  onClick={handleStatusColumnClick}
                  sx={{ cursor: "pointer" }}
                >
                  Status
                  <ArrowDropDownIcon fontSize="small" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell
                    colSpan={8}
                    align="center"
                    sx={{ height: "300px" }}
                  >
                    <LoaderContainer>
                      <ReactLoading
                        type="spin"
                        height={50}
                        width={50}
                        color="grey"
                      />
                    </LoaderContainer>
                  </TableCell>
                </TableRow>
              ) : (
                filteredData.map((asset) => (
                  <TableRow
                    key={asset.assetId}
                    onClick={() => handleRowClick(asset.assetId)}
                    sx={{
                      cursor: "pointer",
                      "&:hover": { backgroundColor: "lightgray" },
                    }}
                  >
                    <TableCell>{asset.assetTypeName}</TableCell>
                    <TableCell>{asset.assetOwnerName || "..."}</TableCell>
                    <TableCell>{asset.assetOwnerPhone || "..."}</TableCell>
                    <TableCell>{asset.stateName || "..."}</TableCell>
                    <TableCell>{asset.cityName || "..."}</TableCell>
                    <TableCell>{asset.regionName || "..."}</TableCell>
                    <TableCell>{asset.status || "..."}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          count={totalPages}
          page={page + 1}
          onChange={handlePageChange}
          color="primary"
          sx={{ mt: 2, display: "flex", justifyContent: "center" }}
        />
        <Popover
          open={Boolean(assetNameAnchorEl)}
          anchorEl={assetNameAnchorEl}
          onClose={handleAssetNameClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <SearchFilterContainer>
            <MultiSelectFilter
              options={assetNameOptions}
              value={selectedAssetName}
              loading={loadingFilter.assetType}
              get_label={(option) => option.name}
              onChange={(event, newValue) => {
                setSelectedAssetName(newValue);
                setPage(0);
              }}
            />
            <ResetFilterButton>
              <Button
                onClick={handleResetAssetNameFilter}
                size="small"
                sx={{ textTransform: "none" }}
              >
                Reset filter
              </Button>
            </ResetFilterButton>
          </SearchFilterContainer>
        </Popover>
        <Popover
          open={Boolean(stateAnchorEl)}
          anchorEl={stateAnchorEl}
          onClose={handleStateClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <SearchFilterContainer>
            <MultiSelectFilter
              options={stateOptions}
              value={selectedState}
              onChange={(event, newValue) => {
                setSelectedState(newValue);
                setPage(0);
              }}
            />
            <ResetFilterButton>
              <Button
                onClick={handleResetStateFilter}
                size="small"
                sx={{ textTransform: "none" }}
              >
                Reset filter
              </Button>
            </ResetFilterButton>
          </SearchFilterContainer>
        </Popover>
        <Popover
          open={Boolean(cityAnchorEl)}
          anchorEl={cityAnchorEl}
          onClose={handleCityClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <SearchFilterContainer>
            <MultiSelectFilter
              options={cityOptions}
              value={selectedCity}
              onChange={(event, newValue) => {
                setSelectedCity(newValue);
                setPage(0);
              }}
            />
            <ResetFilterButton>
              <Button
                onClick={handleResetCityFilter}
                size="small"
                sx={{ textTransform: "none" }}
              >
                Reset filter
              </Button>
            </ResetFilterButton>
          </SearchFilterContainer>
        </Popover>
        <Popover
          open={Boolean(areaAnchorEl)}
          anchorEl={areaAnchorEl}
          onClose={handleAreaClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <SearchFilterContainer>
            <MultiSelectFilter
              options={areaOptions}
              value={selectedArea}
              onChange={(event, newValue) => {
                setSelectedArea(newValue);
                setPage(0);
              }}
            />
            <ResetFilterButton>
              <Button
                onClick={handleResetAreaFilter}
                size="small"
                sx={{ textTransform: "none" }}
              >
                Reset filter
              </Button>
            </ResetFilterButton>
          </SearchFilterContainer>
        </Popover>
        <Popover
          open={Boolean(statusAnchorEl)}
          anchorEl={statusAnchorEl}
          onClose={handleStatusClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <SearchFilterContainer>
            <MultiSelectFilter
              options={statusOptions}
              value={selectedStatus}
              loading={loadingFilter.status}
              get_label={(option) => option.name}
              onChange={(event, newValue) => {
                setSelectedStatus(newValue);
                setPage(0);
              }}
            />
            <ResetFilterButton>
              <Button
                onClick={handleResetStatusFilter}
                size="small"
                sx={{ textTransform: "none" }}
              >
                Reset filter
              </Button>
            </ResetFilterButton>
          </SearchFilterContainer>
        </Popover>
      </Container>
    </SidebarWrapper>
  );
};

export default AssetList;
