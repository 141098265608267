import styled from "styled-components";

export const Container = styled.div`
  padding: 20px;
  height: 100%;
`;

export const Title = styled.h1`
  font-size: 24px;
  margin: 0;
  color: #1d2d4e;
`;

export const TableContainer = styled.div`
  margin-top: 20px;
  background: white;
  overflow: hidden;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ResetFilterButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-top: 1px solid #e0e0e0;
`;

export const SearchFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 287px;
  padding: 5px;
`;
