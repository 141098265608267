import React from "react";
import { Autocomplete, TextField, Paper, Checkbox } from "@mui/material";
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const MultiStateSearchFilter = ({ options, value, onChange }) => {
  // Ensure value is always an array
  const safeValue = Array.isArray(value) ? value : [];

  return (
    <Autocomplete
      multiple
      value={safeValue}
      onChange={onChange}
      options={options || []} // Ensure options is always an array
      getOptionLabel={(option) => option?.name || ""}
      isOptionEqualToValue={(option, value2) => option?.name === value2?.name}
      disableCloseOnSelect
      forcePopupIcon={false}
      PaperComponent={({ children }) => (
        <Paper
          sx={{
            mt: 1,
            boxShadow: "none",
            "& .MuiAutocomplete-listbox": {
              padding: "12px",
            },
          }}
        >
          {children}
        </Paper>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search states"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <SearchIcon
                  sx={{
                    color: "#1976d2",
                    ml: 1.5,
                    mr: 1,
                    fontSize: "1.2rem",
                  }}
                />
                {params.InputProps.startAdornment}
              </>
            ),
            sx: {
              backgroundColor: "#f5f5f5",
              borderRadius: "12px",
              "&:hover": {
                backgroundColor: "#eeeeee",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              padding: "14px 12px",
              fontSize: "0.95rem",
            },
          }}
          sx={{
            "& .MuiInputBase-root": {
              minHeight: "52px",
              height: "auto",
            },
          }}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            checked={selected}
            sx={{ mr: 1 }}
          />
          {option.name}
        </li>
      )}
      open
      ListboxProps={{
        style: {
          maxHeight: "200px",
          position: "relative",
        },
      }}
      PopperComponent={({ children }) => <div>{children}</div>}
    />
  );
};

export default MultiStateSearchFilter;

MultiStateSearchFilter.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func.isRequired,
};

MultiStateSearchFilter.defaultProps = {
  value: [],
};
