import axiosPrivateInstanceFunction from "../../common/helpers/customAxiosInstance";
import {
  SUCCESS,
  FAILURE,
  ACCESS_TOKEN_EXPIRE,
  SOMETHING_WENT_WRONG,
  NETWORK_ERROR,
} from "../../common/constants";
import { BASE_URL } from "../../../config";
import searchTypesMap from "./utils";

export const getAssets = async ({
  page,
  searchType,
  searchText,
  stateIds,
  cityIds,
  regionIds,
  statusIds,
  assetTypeIds,
}) => {
  return new Promise(async (fulfill) => {
    try {
      const params = new URLSearchParams();
      params.append("pageNum", page);
      if (searchText) {
        params.append("searchType", searchTypesMap[searchType]);
        params.append("searchText", searchText);
      }
      if (stateIds?.length > 0) {
        stateIds.forEach((id) => params.append("stateIds[]", id));
      }
      if (cityIds?.length > 0) {
        cityIds.forEach((id) => params.append("cityIds[]", id));
      }
      if (regionIds?.length > 0) {
        regionIds.forEach((id) => params.append("regionIds[]", id));
      }
      if (statusIds?.length > 0) {
        statusIds.forEach((id) => params.append("statusIds[]", id));
      }
      if (assetTypeIds?.length > 0) {
        assetTypeIds.forEach((id) => params.append("assetTypeIds[]", id));
      }
      const response = await axiosPrivateInstanceFunction(BASE_URL).get(
        `/auth/resource/asset/assets`,
        { params }
      );
      if (response.status === 200) {
        fulfill({
          status: SUCCESS,
          data: response.data?.data,
        });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          fulfill({ status: FAILURE, message: ACCESS_TOKEN_EXPIRE });
        } else {
          fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
        }
      } else if (error.request) {
        fulfill({ status: FAILURE, message: NETWORK_ERROR });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    }
  });
};

export const getSearchResults = async ({ searchType, searchText, pageNum }) => {
  return new Promise(async (fulfill) => {
    try {
      const response = await axiosPrivateInstanceFunction(BASE_URL).get(
        `/auth/resource/asset/search-list`,
        { params: { searchType, searchText, pageNum } }
      );
      if (response.status === 200) {
        fulfill({ status: SUCCESS, data: response.data?.data });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          fulfill({ status: FAILURE, message: ACCESS_TOKEN_EXPIRE });
        } else {
          fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
        }
      } else if (error.request) {
        fulfill({ status: FAILURE, message: NETWORK_ERROR });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    }
  });
};
