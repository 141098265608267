import axiosPrivateInstanceFunction from "../helpers/customAxiosInstance";
import {
  SUCCESS,
  FAILURE,
  ACCESS_TOKEN_EXPIRE,
  SOMETHING_WENT_WRONG,
  NETWORK_ERROR,
} from "../constants";
import { BASE_URL } from "../../../config";

export const getAssetTypes = async () => {
  return new Promise(async (fulfill) => {
    try {
      const response = await axiosPrivateInstanceFunction(BASE_URL).get(
        `/auth/resource/asset/assets-type`
      );
      if (response.status === 200) {
        fulfill({ status: SUCCESS, data: response.data.data });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          fulfill({ status: FAILURE, message: ACCESS_TOKEN_EXPIRE });
        } else {
          fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
        }
      } else if (error.request) {
        fulfill({ status: FAILURE, message: NETWORK_ERROR });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    }
  });
};

export const getAssetMake = async () => {
  return new Promise(async (fulfill) => {
    try {
      const response = await axiosPrivateInstanceFunction(BASE_URL).get(
        `/auth/resource/asset/assets-make`
      );
      if (response.status === 200) {
        fulfill({ status: SUCCESS, data: response.data.data });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          fulfill({ status: FAILURE, message: ACCESS_TOKEN_EXPIRE });
        } else {
          fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
        }
      } else if (error.request) {
        fulfill({ status: FAILURE, message: NETWORK_ERROR });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    }
  });
};

export const getAssetOwner = async () => {
  return new Promise(async (fulfill) => {
    try {
      const response = await axiosPrivateInstanceFunction(BASE_URL).get(
        `/auth/resource/asset/assets-owner`
      );
      if (response.status === 200) {
        fulfill({ status: SUCCESS, data: response.data.data });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          fulfill({ status: FAILURE, message: ACCESS_TOKEN_EXPIRE });
        } else {
          fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
        }
      } else if (error.request) {
        fulfill({ status: FAILURE, message: NETWORK_ERROR });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    }
  });
};

export const getAssetStatus = async () => {
  return new Promise(async (fulfill) => {
    try {
      const response = await axiosPrivateInstanceFunction(BASE_URL).get(
        `/auth/resource/asset/assets-status`
      );
      if (response.status === 200) {
        fulfill({ status: SUCCESS, data: response.data.data });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          fulfill({ status: FAILURE, message: ACCESS_TOKEN_EXPIRE });
        } else {
          fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
        }
      } else if (error.request) {
        fulfill({ status: FAILURE, message: NETWORK_ERROR });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    }
  });
};

export const getAssetVendor = async () => {
  return new Promise(async (fulfill) => {
    try {
      const response = await axiosPrivateInstanceFunction(BASE_URL).get(
        `/auth/resource/asset/assets-vendor`
      );
      if (response.status === 200) {
        fulfill({ status: SUCCESS, data: response.data.data });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          fulfill({ status: FAILURE, message: ACCESS_TOKEN_EXPIRE });
        } else {
          fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
        }
      } else if (error.request) {
        fulfill({ status: FAILURE, message: NETWORK_ERROR });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    }
  });
};

export const getAssetsWarrantyType = async () => {
  return new Promise(async (fulfill) => {
    try {
      const response = await axiosPrivateInstanceFunction(BASE_URL).get(
        `/auth/resource/asset/assets-warranty-type`
      );
      if (response.status === 200) {
        fulfill({ status: SUCCESS, data: response.data.data });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          fulfill({ status: FAILURE, message: ACCESS_TOKEN_EXPIRE });
        } else {
          fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
        }
      } else if (error.request) {
        fulfill({ status: FAILURE, message: NETWORK_ERROR });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    }
  });
};

export const getAssetsModel = async (makeId) => {
  return new Promise(async (fulfill) => {
    try {
      const response = await axiosPrivateInstanceFunction(BASE_URL).get(
        `/auth/resource/asset/assets-model?makeId=${makeId}`
      );
      if (response.status === 200) {
        fulfill({ status: SUCCESS, data: response.data.data });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          fulfill({ status: FAILURE, message: ACCESS_TOKEN_EXPIRE });
        } else {
          fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
        }
      } else if (error.request) {
        fulfill({ status: FAILURE, message: NETWORK_ERROR });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    }
  });
};

export const getAssetRoads = async (intersectionId) => {
  return new Promise(async (fulfill) => {
    try {
      const response = await axiosPrivateInstanceFunction(BASE_URL).get(
        `/auth/resource/asset/intersection-road?intersectionId=${intersectionId}`
      );
      if (response.status === 200) {
        fulfill({ status: SUCCESS, data: response.data.data });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          fulfill({ status: FAILURE, message: ACCESS_TOKEN_EXPIRE });
        } else {
          fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
        }
      } else if (error.request) {
        fulfill({ status: FAILURE, message: NETWORK_ERROR });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    }
  });
};

export const getAssetLanes = async (roadId) => {
  return new Promise(async (fulfill) => {
    try {
      const response = await axiosPrivateInstanceFunction(BASE_URL).get(
        `/auth/resource/asset/intersection-lane?roadId=${roadId}`
      );
      if (response.status === 200) {
        fulfill({ status: SUCCESS, data: response.data.data });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          fulfill({ status: FAILURE, message: ACCESS_TOKEN_EXPIRE });
        } else {
          fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
        }
      } else if (error.request) {
        fulfill({ status: FAILURE, message: NETWORK_ERROR });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    }
  });
};

export const getCameraPurpose = async () => {
  return new Promise(async (fulfill) => {
    try {
      const response = await axiosPrivateInstanceFunction(BASE_URL).get(
        `/auth/resource/asset/camera-purpose`
      );
      if (response.status === 200) {
        fulfill({ status: SUCCESS, data: response.data.data });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          fulfill({ status: FAILURE, message: ACCESS_TOKEN_EXPIRE });
        } else {
          fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
        }
      } else if (error.request) {
        fulfill({ status: FAILURE, message: NETWORK_ERROR });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    }
  });
};

export const getCameraDirection = async () => {
  return new Promise(async (fulfill) => {
    try {
      const response = await axiosPrivateInstanceFunction(BASE_URL).get(
        `/auth/resource/asset/camera-directions`
      );
      if (response.status === 200) {
        fulfill({ status: SUCCESS, data: response.data.data });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          fulfill({ status: FAILURE, message: ACCESS_TOKEN_EXPIRE });
        } else {
          fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
        }
      } else if (error.request) {
        fulfill({ status: FAILURE, message: NETWORK_ERROR });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    }
  });
};

export const getAssetStates = async (countryIds) => {
  return new Promise(async (fulfill) => {
    try {
      const params = new URLSearchParams();
      countryIds?.forEach((id) => params.append("countryIds", id));
      const response = await axiosPrivateInstanceFunction(BASE_URL).get(
        `/states-by-country`,
        {
          params,
        }
      );
      if (response.status === 200) {
        fulfill({ status: SUCCESS, data: response.data.data });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          fulfill({ status: FAILURE, message: ACCESS_TOKEN_EXPIRE });
        } else {
          fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
        }
      } else if (error.request) {
        fulfill({ status: FAILURE, message: NETWORK_ERROR });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    }
  });
};

export const getAssetCities = async (stateIds) => {
  return new Promise(async (fulfill) => {
    try {
      const params = new URLSearchParams();
      stateIds?.forEach((id) => params.append("stateIds", id));
      const response = await axiosPrivateInstanceFunction(BASE_URL).get(
        `/cities-by-state`,
        {
          params,
        }
      );
      if (response.status === 200) {
        fulfill({ status: SUCCESS, data: response.data.data });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          fulfill({ status: FAILURE, message: ACCESS_TOKEN_EXPIRE });
        } else {
          fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
        }
      } else if (error.request) {
        fulfill({ status: FAILURE, message: NETWORK_ERROR });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    }
  });
};

export const getAssetRegions = async (cityIds) => {
  return new Promise(async (fulfill) => {
    try {
      const params = new URLSearchParams();
      cityIds?.forEach((id) => params.append("cityIds", id));
      const response = await axiosPrivateInstanceFunction(BASE_URL).get(
        `/regions-by-city`,
        {
          params,
        }
      );
      if (response.status === 200) {
        fulfill({ status: SUCCESS, data: response.data.data });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          fulfill({ status: FAILURE, message: ACCESS_TOKEN_EXPIRE });
        } else {
          fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
        }
      } else if (error.request) {
        fulfill({ status: FAILURE, message: NETWORK_ERROR });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    }
  });
};

export const getIntersectionsByRegion = async (regionIds) => {
  return new Promise(async (fulfill) => {
    try {
      const params = new URLSearchParams();
      regionIds?.forEach((id) => params.append("regionIds", id));
      const response = await axiosPrivateInstanceFunction(BASE_URL).get(
        `/intersections-by-region`,
        {
          params,
        }
      );
      if (response.status === 200) {
        fulfill({ status: SUCCESS, data: response.data.data });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          fulfill({ status: FAILURE, message: ACCESS_TOKEN_EXPIRE });
        } else {
          fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
        }
      } else if (error.request) {
        fulfill({ status: FAILURE, message: NETWORK_ERROR });
      } else {
        fulfill({ status: FAILURE, message: SOMETHING_WENT_WRONG });
      }
    }
  });
};
