import axios from "axios";
import { BASE_URL } from "../../../config";
import { SUCCESS, FAILURE, ACCESS_TOKEN } from "../constants";

const logoutPostApi = async () => {
  return new Promise(async (resolve) => {
    try {
      const accessToken = localStorage.getItem(ACCESS_TOKEN);
      await axios.post(
        `${BASE_URL}/auth/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      resolve({ status: SUCCESS });
    } catch (error) {
      resolve({ status: FAILURE, message: error.message });
    }
  });
};

export default logoutPostApi;
