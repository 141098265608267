function BeltechLogoIcon() {
  return (
    <svg
      width="200"
      height="80"
      viewBox="0 0 855 381"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M158.479 317.383V111.907L228.27 132.571V317.383H158.479Z"
        fill="#3988FF"
      />
      <path
        d="M103.113 131.012L158.868 111.907V317.383H103.113V131.012Z"
        fill="#50B590"
      />
      <path
        d="M158.868 196.905L94.1455 182.479V317.383H158.868V196.905Z"
        fill="#FCC500"
      />
      <path
        d="M38 194.532L94.1453 182.479V317.383H38V194.532Z"
        fill="#F58706"
      />
      <path
        d="M158.002 92C164.982 92 171.571 94.6826 176.077 99.3614C177.475 100.812 177.261 102.975 175.594 104.195C174.856 104.734 173.959 105 173.064 105C171.942 105 170.826 104.584 170.049 103.776C167.04 100.653 162.647 98.8629 158 98.8629C153.352 98.8629 148.96 100.654 145.95 103.776C145.17 104.586 144.055 105 142.935 105C142.041 105 141.143 104.737 140.405 104.195C138.739 102.975 138.524 100.812 139.922 99.3614C144.432 94.6838 151.021 92 158.002 92Z"
        fill="#92BADD"
      />
      <path
        d="M115.921 81.3164C126.424 70.3099 141.76 64 158.001 64C174.238 64 189.575 70.3099 200.079 81.3164C201.474 82.7781 201.26 84.9589 199.597 86.1881C198.861 86.7329 197.968 87 197.076 87C195.956 87 194.843 86.5803 194.067 85.7661C185.061 76.3297 171.915 70.9163 158 70.9163C144.086 70.9163 130.939 76.3297 121.933 85.7638C121.156 86.5774 120.043 86.9978 118.923 86.9978C118.03 86.9978 117.135 86.7324 116.4 86.1865C114.74 84.959 114.525 82.7752 115.921 81.3164Z"
        fill="#92BADD"
      />
      <path
        d="M158.002 92C164.982 92 171.571 94.6826 176.077 99.3614C177.475 100.812 177.261 102.975 175.594 104.195C174.856 104.734 173.959 105 173.064 105C171.942 105 170.826 104.584 170.049 103.776C167.04 100.653 162.647 98.8629 158 98.8629C153.352 98.8629 148.96 100.654 145.95 103.776C145.17 104.586 144.055 105 142.935 105C142.041 105 141.143 104.737 140.405 104.195C138.739 102.975 138.524 100.812 139.922 99.3614C144.432 94.6838 151.021 92 158.002 92Z"
        fill="#92BADD"
      />
      <path
        d="M158.002 78C169.61 78 180.57 82.4945 188.078 90.3326C189.475 91.7895 189.26 93.9647 187.596 95.1899C186.86 95.7331 185.964 95.9994 185.071 95.9994C183.95 95.9994 182.836 95.5809 182.06 94.769C176.054 88.4963 167.283 84.8974 158.001 84.8974C148.721 84.8974 139.948 88.4957 133.939 94.7696C133.161 95.5838 132.047 96 130.929 96C130.035 96 129.139 95.7354 128.403 95.1905C126.74 93.9653 126.525 91.7884 127.921 90.3331C135.432 82.495 146.394 78 158.003 78H158.002Z"
        fill="#92BADD"
      />
      <path
        d="M115.921 81.3164C126.424 70.3099 141.76 64 158.001 64C174.238 64 189.575 70.3099 200.079 81.3164C201.474 82.7781 201.26 84.9589 199.597 86.1881C198.861 86.7329 197.968 87 197.076 87C195.956 87 194.843 86.5803 194.067 85.7661C185.061 76.3297 171.915 70.9163 158 70.9163C144.086 70.9163 130.939 76.3297 121.933 85.7638C121.156 86.5774 120.043 86.9978 118.923 86.9978C118.03 86.9978 117.135 86.7324 116.4 86.1865C114.74 84.959 114.525 82.7752 115.921 81.3164Z"
        fill="#92BADD"
      />
      <path
        d="M313.509 314.769H276V232H316.205C326.349 232.15 333.537 235.089 337.767 240.816C340.313 244.335 341.585 248.547 341.585 253.451C341.585 258.504 340.313 262.566 337.767 265.636C336.345 267.358 334.248 268.93 331.478 270.352C335.708 271.887 338.89 274.321 341.024 277.652C343.195 280.984 344.281 285.027 344.281 289.781C344.281 294.685 343.045 299.084 340.575 302.977C339.002 305.56 337.037 307.731 334.679 309.491C332.021 311.512 328.876 312.897 325.245 313.646C321.651 314.395 317.739 314.769 313.509 314.769ZM313.116 278.326H292.508V300.394H312.835C316.467 300.394 319.293 299.907 321.314 298.934C324.983 297.137 326.817 293.693 326.817 288.602C326.817 284.297 325.039 281.34 321.483 279.73C319.499 278.831 316.71 278.364 313.116 278.326ZM321.708 262.547C323.954 261.2 325.077 258.785 325.077 255.304C325.077 251.448 323.579 248.902 320.584 247.667C318.001 246.806 314.707 246.375 310.702 246.375H292.508V264.625H312.835C316.467 264.625 319.424 263.932 321.708 262.547Z"
        fill="white"
      />
      <path
        d="M409.923 296.8C409.511 300.431 407.621 304.119 404.251 307.862C399.011 313.814 391.673 316.79 382.24 316.79C374.453 316.79 367.584 314.282 361.632 309.266C355.68 304.25 352.704 296.089 352.704 284.784C352.704 274.19 355.38 266.066 360.733 260.414C366.124 254.761 373.106 251.935 381.678 251.935C386.769 251.935 391.355 252.889 395.436 254.798C399.516 256.707 402.885 259.721 405.543 263.839C407.939 267.47 409.492 271.681 410.204 276.473C410.615 279.281 410.784 283.324 410.709 288.602H368.819C369.044 294.741 370.972 299.046 374.603 301.517C376.812 303.052 379.47 303.819 382.577 303.819C385.871 303.819 388.548 302.883 390.606 301.012C391.73 300.001 392.722 298.597 393.583 296.8H409.923ZM394.088 278.045C393.826 273.815 392.534 270.615 390.213 268.443C387.93 266.235 385.085 265.13 381.678 265.13C377.972 265.13 375.09 266.291 373.031 268.612C371.009 270.933 369.737 274.077 369.212 278.045H394.088Z"
        fill="white"
      />
      <path d="M421.883 232H437.887V314.769H421.883V232Z" fill="white" />
      <path
        d="M481.573 303.37V315.331L473.993 315.611C466.431 315.873 461.265 314.563 458.495 311.681C456.698 309.846 455.799 307.02 455.799 303.202V265.523H447.264V254.124H455.799V237.054H471.634V254.124H481.573V265.523H471.634V297.867C471.634 300.375 471.952 301.948 472.589 302.584C473.225 303.183 475.172 303.482 478.429 303.482C478.915 303.482 479.421 303.482 479.945 303.482C480.506 303.445 481.049 303.408 481.573 303.37Z"
        fill="white"
      />
      <path
        d="M544.239 296.8C543.827 300.431 541.937 304.119 538.568 307.862C533.327 313.814 525.99 316.79 516.556 316.79C508.77 316.79 501.9 314.282 495.948 309.266C489.996 304.25 487.02 296.089 487.02 284.784C487.02 274.19 489.697 266.066 495.05 260.414C500.44 254.761 507.422 251.935 515.995 251.935C521.086 251.935 525.672 252.889 529.752 254.798C533.832 256.707 537.202 259.721 539.859 263.839C542.255 267.47 543.809 271.681 544.52 276.473C544.932 279.281 545.1 283.324 545.025 288.602H503.136C503.36 294.741 505.288 299.046 508.919 301.517C511.128 303.052 513.786 303.819 516.893 303.819C520.187 303.819 522.864 302.883 524.923 301.012C526.046 300.001 527.038 298.597 527.899 296.8H544.239ZM528.404 278.045C528.142 273.815 526.851 270.615 524.53 268.443C522.246 266.235 519.401 265.13 515.995 265.13C512.289 265.13 509.406 266.291 507.347 268.612C505.326 270.933 504.053 274.077 503.529 278.045H528.404Z"
        fill="white"
      />
      <path
        d="M608.814 275.519H592.418C592.119 273.235 591.351 271.176 590.116 269.342C588.319 266.871 585.53 265.636 581.749 265.636C576.358 265.636 572.671 268.312 570.687 273.665C569.639 276.511 569.115 280.291 569.115 285.008C569.115 289.5 569.639 293.113 570.687 295.846C572.596 300.937 576.19 303.482 581.468 303.482C585.212 303.482 587.87 302.472 589.442 300.45C591.014 298.429 591.969 295.808 592.306 292.589H608.646C608.272 297.455 606.512 302.06 603.368 306.402C598.351 313.403 590.921 316.903 581.075 316.903C571.23 316.903 563.986 313.983 559.344 308.143C554.702 302.303 552.381 294.723 552.381 285.401C552.381 274.882 554.946 266.703 560.074 260.863C565.203 255.023 572.278 252.103 581.3 252.103C588.974 252.103 595.244 253.825 600.111 257.269C605.015 260.713 607.916 266.796 608.814 275.519Z"
        fill="white"
      />
      <path
        d="M675.187 278.831V314.769H658.846V277.54C658.846 274.246 658.285 271.588 657.162 269.566C655.702 266.721 652.931 265.299 648.851 265.299C644.621 265.299 641.402 266.721 639.193 269.566C637.022 272.374 635.936 276.398 635.936 281.639V314.769H619.989V232.281H635.936V261.537C638.257 257.98 640.934 255.51 643.966 254.124C647.035 252.702 650.255 251.991 653.624 251.991C657.405 251.991 660.83 252.646 663.9 253.956C667.007 255.266 669.553 257.269 671.537 259.964C673.221 262.248 674.251 264.606 674.625 267.04C674.999 269.435 675.187 273.366 675.187 278.831Z"
        fill="white"
      />
      <path
        d="M767.669 297.755H737.179L731.451 314.769H713.37L742.906 232H762.447L791.759 314.769H773.004L767.669 297.755ZM762.84 283.492L752.508 250.924L741.839 283.492H762.84Z"
        fill="white"
      />
      <path d="M800.968 232H818.15V314.769H800.968V232Z" fill="white" />
    </svg>
  );
}
export default BeltechLogoIcon;
