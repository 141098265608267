import { useState, useEffect, useRef, useCallback } from "react";
import {
  Paper,
  Select,
  MenuItem,
  InputBase,
  Popper,
  ClickAwayListener,
  IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { SearchContainer } from "../style";
import {
  SUCCESS,
  FAILURE,
  ACCESS_TOKEN_EXPIRE,
} from "../../../common/constants";
import { getSearchResults } from "../services";
import debounce from "../../../common/helpers/debounce";
import { logoutUser } from "../../../common/helpers";

const DEBOUNCE_TIME = 200;

const SearchComponent = ({
  searchType,
  setSearchType,
  searchQuery,
  setSearchQuery,
  setId,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchAnchorEl, setSearchAnchorEl] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const observerRef = useRef();

  const fetchSearchResults = async (searchType1, value1, pageNum1) => {
    const response = await getSearchResults({
      searchType: searchType1,
      searchText: value1,
      pageNum: pageNum1,
    });
    if (response.status === SUCCESS) {
      if (response.data.searchList.length < 10) {
        console.log(
          "Setting hasMore to false",
          response.data.searchList.length
        );
        setHasMore(false);
      }
      console.log("Setting page to", pageNum1 + 1);
      setPage((prev) => prev + 1);
      if (pageNum1 === 0) {
        setSearchResults(response.data.searchList || []);
      } else {
        setSearchResults((prev) => [...prev, ...response.data.searchList]);
      }
    } else if (
      response.status === FAILURE &&
      response.message === ACCESS_TOKEN_EXPIRE
    ) {
      logoutUser(dispatch, navigate);
    } else {
      alert(response.message);
    }
  };

  const lastElementRef = useCallback(
    (node) => {
      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => {
            console.log("Logging the value of page", prevPage);
            fetchSearchResults(searchType, searchQuery, prevPage);
            return prevPage;
          });
        }
      });

      if (node) observerRef.current.observe(node);
    },
    [hasMore] // Add other dependencies if needed
  );

  // Reset pagination when search query changes
  useEffect(() => {
    setPage(0);
    setHasMore(true);
    setSearchResults([]);
    setSearchQuery("");
  }, [searchType]);

  const handleSearchSelect = (asset) => {
    console.log("handleSearchSelect", asset);
    // handleRowClick(asset.assetId);
    setSearchAnchorEl(null);
    // setSearchResults([]);
    setId(asset.id);
  };

  const debouncedFetchSearchResults = useCallback(
    debounce(fetchSearchResults, DEBOUNCE_TIME),
    [dispatch, navigate]
  );

  const handleSearchChange = async (e) => {
    const { value } = e.target;
    setSearchQuery(value);
    setSearchAnchorEl(e.currentTarget);
    setPage(0);
    debouncedFetchSearchResults(searchType, value, 0);
  };
  return (
    <SearchContainer>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: 400,
          boxShadow: "none",
          border: "1px solid #e0e0e0",
          borderRadius: "4px",
          height: "42px",
        }}
      >
        <Select
          value={searchType}
          onChange={(e) => {
            setSearchType(e.target.value);
          }}
          variant="standard"
          disableUnderline
          sx={{
            ml: 1,
            width: "140px",
            "& .MuiSelect-select": { py: 0.5 },
          }}
        >
          <MenuItem value="asset_id">Asset ID</MenuItem>
          <MenuItem value="serial_number">Serial Number</MenuItem>
          <MenuItem value="owner_name">Asset Owner</MenuItem>
          <MenuItem value="owner_phone_number">Owner Phone No.</MenuItem>
        </Select>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder={`Search by ${searchType}`}
          value={searchQuery}
          onChange={handleSearchChange}
          endAdornment={
            searchQuery && (
              <IconButton
                size="small"
                onClick={() => {
                  setSearchQuery("");
                  setId(null);
                }}
                sx={{ p: "4px" }}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            )
          }
        />
      </Paper>

      <Popper
        open={Boolean(searchAnchorEl) && searchResults?.length > 0}
        anchorEl={searchAnchorEl}
        placement="bottom-start"
        style={{ width: 400, zIndex: 1300 }}
      >
        <ClickAwayListener onClickAway={() => setSearchAnchorEl(null)}>
          <Paper sx={{ mt: 1, maxHeight: 300, overflow: "auto" }}>
            {searchResults.map((asset, index) => (
              <MenuItem
                ref={
                  index === searchResults.length - 1
                    ? lastElementRef
                    : undefined
                }
                key={asset.id}
                onClick={() => handleSearchSelect(asset)}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  py: 1,
                }}
              >
                <div style={{ fontWeight: "bold" }}>{asset.displayText}</div>
              </MenuItem>
            ))}
            {!hasMore && searchResults.length > 0 && (
              <MenuItem disabled sx={{ justifyContent: "center" }}>
                No more results
              </MenuItem>
            )}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </SearchContainer>
  );
};

SearchComponent.propTypes = {
  searchType: PropTypes.oneOf([
    "asset_id",
    "serial_number",
    "owner_name",
    "owner_phone_number",
  ]).isRequired,
  setSearchType: PropTypes.func.isRequired,
  searchQuery: PropTypes.string.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
  setId: PropTypes.func.isRequired,
};

export default SearchComponent;
